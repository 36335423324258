<template>
  <div class="pattern">
    <div class="headerBox">
      <i class="el-icon-alidanlieliebiao"></i>
      <i class="el-icon-aliiconfontzhizuobiaozhun023101" @click="goHome"></i>
    </div>
    <div class="contentBox">
      <div class="leftBox">
        <div class="backHome" @click="goHome">
          <i class="el-icon-alifanhui2"></i>
          <span>返 回</span>
        </div>
        <div class="leftMain">
          <div class="leftTitle">{{projectName}}</div>
          <ul>
            <li v-for="(item,index) in practiceList" :key="index">
              <span>{{item.Category}}</span> -------------------- <span>{{item.speed}}/{{item.PractiseItemList.length}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="rightBox">
        <div class="rightMain">
          <ul>
            <li v-for="(item,index) in practiceList" :key="index">
              <div class="rightTitle"><span>{{item.Category}}</span></div>
              <div class="boxInfo" v-for="(item2,index2) in item.PractiseItemList" :key="index2" @click="clickInfo(item2)">
                <span>{{item2.ItemName}}</span>
                <span class="complete" v-if="item2.State == 0">已完成</span>
                <span class="progress" v-if="item2.State == 1">进行中</span>
                <span class="incomplete" v-if="item2.State == 2">未完成</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectId: "",
      projectName: "",
      practiceList: [],
    }
  },
  methods: {
    initData() {
      this.$axios.post('api/LearningProjects/PractiseConfigList/' + this.projectId,).then(res => {
        let a = JSON.parse(res.data);
        if (a.code == 200) {
          this.practiceList = a.data;
          this.practiceList.forEach(v => {
            let sum = 0;
            v.PractiseItemList.forEach(v2 => {
              if (v2.State == 0) {
                sum++;
                this.$set(v, "speed", sum);
              } else {
                this.$set(v, "speed", sum);
              }
            });
          });
        }
      });
    },
    goHome() {
      this.$router.push({ name: "project" });
    },
    clickInfo(item2) {
      this.cookies.kkSet("pattemNmae", item2.ItemName)
      this.$router.push({ name: "train" });
    }
  },
  mounted() {
    this.projectId = this.cookies.kkGet("projectId");
    this.projectName = this.cookies.kkGet("projectName");
    this.initData();
  }
}
</script>

<style scoped>
.pattern {
  width: 100%;
  height: 100%;
}
.headerBox {
  width: 100%;
  height: 88px;
  background: #4fc1b0;
  line-height: 88px;
}
.headerBox i {
  font-size: 32px;
  color: #fff;
  font-weight: bold;
  margin-left: 20px;
}
.contentBox {
  width: 100%;
  height: calc(100% - 88px);
  display: flex;
}

/* 左侧样式 */
.contentBox .leftBox {
  flex: 1;
  background: #91b1d8;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #fff;
  text-align: center;
  position: relative;
}
.contentBox .leftBox .backHome {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  line-height: 30px;
}
.contentBox .leftBox .backHome i {
  color: #fff;
  font-size: 30px;
}
.contentBox .leftBox .backHome span {
  font-size: 18px;
  font-weight: bold;
  margin-left: 15px;
}
.contentBox .leftBox .leftMain {
  width: 100%;
}
.contentBox .leftBox .leftMain .leftTitle {
  font-size: 28px;
}
.contentBox .leftBox .leftMain ul {
  margin-top: 100px;
  font-size: 18px;
}
.contentBox .leftBox .leftMain ul li {
  margin-bottom: 50px;
}

/* 右侧样式 */
.contentBox .rightBox {
  flex: 1;
  display: flex;
  align-items: center;
}
.contentBox .rightBox .rightMain {
  width: 80%;
  height: 560px;
  margin: 0 auto;
  border: 1px solid #91b1d8;
  padding: 30px 25px 0;
  overflow-y: auto;
}
.contentBox .rightBox .rightMain li {
  margin-bottom: 50px;
}
.contentBox .rightBox .rightMain .rightTitle {
  color: #4fc1b0;
  font-size: 16px;
  font-weight: bold;
  height: 36px;
  line-height: 36px;
  border-bottom: 1px solid #91b1d8;
}
.contentBox .rightBox .rightMain .rightTitle span {
  margin-left: 10px;
}
.contentBox .rightBox .rightMain .boxInfo {
  height: 48px;
  line-height: 48px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #91b1d8;
  border-radius: 3px;
  margin-top: 10px;
}
.contentBox .rightBox .rightMain .boxInfo .complete {
  color: #4fc1b0;
}
.contentBox .rightBox .rightMain .boxInfo .incomplete {
  color: #797979;
}
.contentBox .rightBox .rightMain .boxInfo .progress {
  color: red;
}
</style>